import { defineStore } from 'pinia'

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useMainStore = defineStore('store', {
	state: () => {
		return {
			// all these properties will have their type inferred automatically
			user: {
				id: -1,
				firstname: 'Jane',
				lastname: 'Smith',
				phonenumber: '',
				email: 'jane@dfm.nl',
				// - 0 = Werkgever
				// - 1 = Werknemer
				role: 1, 
		
				profilepicture: 'https://admin-dfm.dev-project.digital/cdn/img/person.jpg',
				title: '',
				bio: '',
				dateofbirth: '',
				register_date: 0,
				perms: ['userlevel_admin'],

				// settings
				email_notifications: true,

			},

			companypage: {
				// Brand
				logo: 'https://admin-dfm.dev-project.digital/cdn/img/klant-logo.svg',
				companyname: 'Sanday',
				primary_color: '#908CC3',
				banner_image: 'https://admin-dfm.dev-project.digital/cdn/img/Dit-is-sanday-voor-jou.jpg',
				
				// Topbar
				topbartitle: 'Digitale Zorgverbinders',

				quote: 'Dit is onze quote!',
				description: 'Laten we samen een pauze nemen van ons dagelijkse werk en verbinden tijdens onze komende activiteiten. Of je nu van sport, cultuur of gewoon gezelligheid houdt, er is voor ieder wat wils. Meld je aan en laten we samen onvergetelijke herinneringen creëren!',
				language: 'NL',
				

				dfm_contactperson: {
					profilepicture: 'https://admin-dfm.dev-project.digital/cdn/img/person.jpg',
					name: 'Jose',
					phonenumber: '0306016620',
					email: 'jose@defittemedewerker.nl',
					adres: 'Spinnekop 2, 1444GN Purmerend',
				}


			},
		}
	},
})



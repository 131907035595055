<template>


            <div class="pa-3 text-left">
                <h1>Geen toegang</h1>
                <p>Je account heeft niet de juiste rechten voor dit scherm.</p>
                <k-spacing y="5"></k-spacing>
                <v-btn :color="'var(--company-primary)'" class="btn text-white" to="/">Naar Dashboard</v-btn>
            </div>
  
   
</template>

<script>
export default {

};
</script>

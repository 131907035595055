import { createRouter, createWebHistory } from 'vue-router';

// import store from '@/store';


const routes = [
	{
		path: '/',
		component: () =>
			import( /* webpackChunkName: "main" */ '../views/__template'),
		children: [{
			path: '',
			component: () =>
				import( /* webpackChunkName: "main" */ '../views/Dashboard'),
		},
		{
			path: '/:catchAll(.*)',
			component: () =>
				import( /* webpackChunkName: "404" */ '../views/404')
		},
		{
			path: '/activiteiten/:catchAll(.*)',
			component: () =>
				import( /* webpackChunkName: "404" */ '../views/404-activiteiten')
		}
		]
	},
	{
		path: '/bedrijfspagina',
		component: () =>
			import( /* webpackChunkName: "main" */ '../views/companypages/__template'),
		children: [{
			path: '',
			component: () =>
				import( /* webpackChunkName: "main" */ '../views/companypages/Overview'),
		},
	

		]
	},	

	{
		path: '/contact',
		component: () =>
			import( /* webpackChunkName: "main" */ '../views/contact/__template'),
		children: [{
			path: '',
			component: () =>
				import( /* webpackChunkName: "main" */ '../views/contact/Overview'),
		},
	

		]
	},	
	{
		path: '/activiteiten',
		component: () =>
		import( /* webpackChunkName: "main" */ '../views/companypages/Overview'),
	},


	{
		path: '/activiteiten/:id',
		component: () =>
		import( /* webpackChunkName: "main" */ '../views/activities/__template'),
		children: [{
			path: '',
			component: () =>
				import( /* webpackChunkName: "main" */ '../views/activities/View'),
		},
		{
			path: 'aanmeldingen',
			component: () =>
				import( /* webpackChunkName: "main" */ '../views/activities/Registrations'),
		},
		
		]
	},




	{
		path: '/accountgegevens',
		component: () =>
			import( /* webpackChunkName: "main" */ '../views/account/Overview'),
	},

	{
		path: '/instellingen',
		component: () =>
			import( /* webpackChunkName: "main" */ '../views/settings/__template'),
		children: [{
			path: '',
			component: () =>
				import( /* webpackChunkName: "main" */ '../views/settings/Overview'),
		},
		

		]
	},

	{
		path: '/inloggen',
		component: () =>
			import( /* webpackChunkName: "werkgevers" */ '../views/auth/__template'),
		children: [
			{
				path: '',
				component: () =>
					import('../views/auth/Login'),
			},

		]
	},

	{
		path: '/wachtwoord-vergeten',
		component: () =>
			import( /* webpackChunkName: "werkgevers" */ '../views/auth/__template'),
		children: [
			{
				path: '',
				component: () =>
					import('../views/auth/Resetpassword'),
			},

		]
	},


	{
		path: '/wachtwoord-wijzigen',
		component: () =>
			import( /* webpackChunkName: "werkgevers" */ '../views/auth/__template'),
		children: [
			{
				path: '',
				component: () =>
					import('../views/auth/Newpassword'),
			},

		]
	},

	{
		path: '/registratie-voltooien',
		component: () =>
			import( /* webpackChunkName: "register confirm" */ '../views/auth/__template'),
		children: [
			{
				path: '',
				component: () =>
					import('../views/auth/Registerconfirm'),
			},

		]
	},





];

const router = createRouter({
	history: createWebHistory(),
	routes,
});


export default router
<template>
  <router-view v-if="ready" />
</template>

<script>
// const cookies = require("js-cookie");
// import api from "./services/api";
// import config from "./config/index";
import { useMainStore } from "@/stores/store";
export default {
  setup() {
    const mainStore = useMainStore();
    return {
      companyPrimaryColor: mainStore.companypage.primary_color,
    };
  },


  data: () => ({
    ready: false,
  }),

  beforeMount() {
    // if (
    //   !location.pathname.startsWith("/login") &&
    //   !location.pathname.startsWith("/welcome") &&
    //   !location.pathname.startsWith("/register") &&
    //   !location.pathname.startsWith("/reset-password") && 
    //   !location.pathname.startsWith("/new-password")


    // ) {
    //   if (cookies.get("chrp_refresh_token")) {
    //     api
    //       .get(`/v1/users/me/info`)
    //       .then((res) => {
    //         useMainStore().$patch({
    //           user: res.data,
    //         });
    //         this.ready = true;
    //       })
    //       .catch((err) => {
    //         if (err.response.status === 401) {
    //           cookies.remove("chrp_access_token", {
    //             domain: config.cookiedomain,
    //             path: "/",
    //           });
    //           cookies.remove("chrp_refresh_token", {
    //             domain: config.cookiedomain,
    //             path: "/",
    //           });
    //           cookies.remove("chrp_user", {
    //             domain: config.cookiedomain,
    //             path: "/",
    //           });
    //           location.replace(
    //             `${config.url.login}/login?r=${encodeURI(location.href)}`
    //           );
    //         } else {
    //           location.replace(
    //             `${config.url.login}/login?r=${encodeURI(location.href)}`
    //           );
    //         }
    //       });
    //   } else {
    //     location.replace(
    //       `${config.url.login}/login?r=${encodeURI(location.href)}`
    //     );
    //   }
    // } else {
      this.ready = true;
    // }
  },


  methods: {
    setCompanyPrimaryColor() {
      document.documentElement.style.setProperty('--company-primary', this.companyPrimaryColor);
    },
  },

  created() {
    this.setCompanyPrimaryColor(); // Call the method during the component's creation
  },
};
</script>

<style>
.text-company-primary {
    color: var(--company-primary)!important;
}
</style>
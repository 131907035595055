<template>
  <div>
    <div v-if="step === 1" class="stepper-view">
      <span class="step-nr">Stap 1/4</span>
      <k-spacing y="8"></k-spacing>
      <p class="step-subtitle">Aanmelden</p>
      <h3 class="step-title">Lachen om workshop</h3>
      <k-spacing y="8"></k-spacing>
      <label class="input-label" for="choose-settling">Vestiging kiezen</label>
      <v-select density="compact" variant="solo" placeholder="Selecteer een vestiging" name="choose-settling"
        validate-on-blur />



      <label class="input-label" for="choose-specialist">Specialist kiezen</label>
      <v-select density="compact" variant="solo" placeholder="Selecteer een specialist" name="choose-specialist"
        validate-on-blur />


      <k-spacing y="8"></k-spacing>
      <v-row>
        <v-col cols="12" class="d-flex">
          <v-btn class="btn" color="var(--company-primary)" variant="outlined">
            Annuleren
          </v-btn>
          <k-spacing x="4"></k-spacing>
          <v-btn class="btn text-white" color="var(--company-primary)" @click="step = 2">
            Volgende
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="step === 2" class="stepper-view">
      <span class="step-nr">Stap 2/4</span>
      <k-spacing y="8"></k-spacing>
      <p>Aanmelden</p>
      <h3>Lachen om workshop</h3>
      <k-spacing y="8"></k-spacing>
      <label class="input-label" for="choose-date">Dag(en) kiezen</label>
      <v-date-picker
      class="date-picker-card"
      name="choose-date"
      title="Dagen selecteren"
      text="Datum invoeren"
      color="var(--company-primary)"
      ></v-date-picker>


      <k-spacing y="8"></k-spacing>
      <v-row>
        <v-col cols="12" class="d-flex">
          <v-btn class="btn" color="var(--company-primary)" variant="outlined" @click="step = 2">
            Vorige
          </v-btn>
          <k-spacing x="4"></k-spacing>
          <v-btn class="btn text-white" color="var(--company-primary)" @click="step = 3">
            Volgende
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="step === 3" class="stepper-view">
      <span>Stap 3/4</span>
      <k-spacing y="8"></k-spacing>
      <p>Aanmelden</p>
      <h3>Lachen om workshop</h3>
      <k-spacing y="8"></k-spacing>

      <k-spacing y="8"></k-spacing>
      <v-row>
        <v-col cols="12" class="d-flex">
          <v-btn class="btn" color="var(--company-primary)" variant="outlined" @click="step = 3">
            Vorige
          </v-btn>
          <k-spacing x="4"></k-spacing>
          <v-btn class="btn text-white" color="var(--company-primary)" @click="step = 4">
            Volgende
          </v-btn>
        </v-col>
      </v-row>
    </div>


    <div v-if="step === 4" class="stepper-view">
      <span>Stap 4/4</span>
      <k-spacing y="8"></k-spacing>
      <p>Aanmelden</p>
      <h3>Lachen om workshop</h3>
      <k-spacing y="8"></k-spacing>

      <k-spacing y="8"></k-spacing>
      <v-row>
        <v-col cols="12" class="d-flex">
          <v-btn class="btn" color="var(--company-primary)" variant="outlined" @click="step = 4">
            Vorige
          </v-btn>
          <k-spacing x="4"></k-spacing>
          <v-btn class="btn text-white " color="var(--company-primary)" @click="step = 5">
            Volgende
          </v-btn>
        </v-col>
      </v-row>
    </div>




  </div>
</template>

<script>
export default {
  components: {},

  props: {
    title: String,    
  },
  data: () => ({
    step: 1,
  }),

  methods: {
    deleteitem() {

    }
  }
};
</script>

<style>
.step-nr {
  color: var(--neutral-500, #858585);
  /* Paragraph */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 28.8px */
}
</style>